import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Confirm = ({ handleConfirmClick, heading, text }) => {
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-open');
    return () => {
      body.classList.remove('modal-open');
    };
  }, []);

  return (
    <div className="modal confirm d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header no-border align-items-center">
            <h6 className="modal-title text-left semi-bold text-primary">{heading}</h6>
            <button type="button" className="btn" onClick={() => handleConfirmClick(false)}>
              <i className="icon-close" />
            </button>
          </div>
          <div className="modal-body text-left px-4 pt-0">
            <p className="font-medium mt-2 text-secondGray">{text}</p>
            <div className="d-flex  button-wrapper mb-3">
              <button
                type="button"
                className="btn btn-primary zipps-bold btn-block rouded-button   mt-0"
                onClick={() => handleConfirmClick(true)}
              >
                Yes
              </button>

              <button
                type="button"
                className="btn btn-outline-primary zipps-bold btn-block rouded-button   mt-0"
                onClick={() => handleConfirmClick(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Confirm.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  handleConfirmClick: PropTypes.func.isRequired
};

Confirm.defaultProps = {
  text: '',
  heading: 'Delete Card'
};

export default Confirm;
