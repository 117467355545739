import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import SearchBar from '../SearchBarCustom';
import AddressHistoryItem from '../AddressHistoryItem';
import { useAddressHistory, useFullAddressHistory } from '../../hooks/User';
import appContext from '../../Context/appContext';
import { SCHEDULE_TYPE_ASAP, SCHEDULE_TYPE_ASAP_PRIORITY, SCHEDULE_TYPE_FUTURE } from '../../constants/scheduleType';
import { toMonthAndDay, getCurrentTime } from '../../helpers/timeHelpers';
import getFirstAvailableTimeSlot from '../../helpers/getFirstAvailableTimeSlot';
import getDefaultDeliveryDate from '../../helpers/getDefaultDeliveryDate';
import { DEFAULT_DATETIME, DEFAULT_ADDRESS } from '../../constants/default';
import StorageService from '../../services/storageService';
import notificationTypes from '../../constants/modalNotificationConst';
import useSegment from '../../hooks/segment';
import useAPI from '../../NetworkCall/API';
import { useDeliveryDetails } from '../../hooks/app';
import appConfig from '../../appConfig';
import Confirm from '../Confirm';
import isMobile from '../../helpers/isMobile';
import storageService from '../../services/storageService';
import { useDispatch, useSelector } from 'react-redux';
import { setCartData, setCustomerInfo } from '../../redux/slices/cartSlice';
import { pushNotification } from '../../redux/slices/modalSlice';
import { saveLocation } from '../../redux/slices/userSlice';
import { updateCheckOutDetails } from '../../redux/slices/otherSlice';

const EditAddress = ({ error, isLoggedIn, isMedicalCard, isNotfocus, className, fromCheckout = false }) => {
  // const {
  //   state: {
  //     // user: {
  //     //   deliveryDetails: {
  //     //     address,
  //     //     address_id: addressId,
  //     //     deliveryTypeSelected,
  //     //     hasGeofenceInventory,
  //     //     streetNumber,
  //     //     streetName,
  //     //     postcode
  //     //   },
  //     //   deliveryDetails: initialDeliveryDetails
  //     // },
  //     // cart: {
  //     //   cartData = {},
  //     //   customerData: { addressError }
  //     // },
  //     // checkOutUpdateDetails
  //   },
  //   dispatch
  // } = useContext(appContext);
  const dispatchRedux = useDispatch()
  const checkOutUpdateDetails = useSelector(state => state.other.checkOutUpdateDetails)

  const {
    deliveryDetails: {
      address,
      address_id: addressId,
      deliveryTypeSelected,
      hasGeofenceInventory,
      streetNumber,
      streetName,
      postcode
    },
    deliveryDetails: initialDeliveryDetails
  } = useSelector(state => state.user)
  const {
    cartData = {},
    customerData: { addressError } = {} } = useSelector(state => state.cart) || {}

  const { data: addressHistory, mutate: addressHistoryMutate } = useAddressHistory();

  const { trackEvent } = useSegment();

  const geoFenceRef = useRef(hasGeofenceInventory);

  useEffect(() => {
    geoFenceRef.current = hasGeofenceInventory;
  }, [hasGeofenceInventory]);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [addressToDeleted, setAddressToDelete] = useState(null);

  const [editAddress, setEditAddress] = useState(!address);
  const [addressList, setAddressList] = useState();
  const { checkZipCodeStatus, deleteHistoryAddress, getPlaceId } = useAPI();
  const {
    data: { deliveryDays, asapEnabled = true }
  } = useDeliveryDetails();

  useEffect(() => {
    if (addressHistory && isLoggedIn) {
      const defaultAddress = addressHistory.filter(address => address.is_default == 1);
      // dispatch({ type: 'saveLocation', payload: defaultAddress });
      dispatchRedux(saveLocation(defaultAddress))
    }
  }, [isLoggedIn]);

  const [isDefaultAddress, setDefaultAddress] = useState(false);

  useEffect(() => {
    if (address === DEFAULT_ADDRESS) {
      return setDefaultAddress(true);
    }

    // dispatch({ type: 'setCustomerInfo', payload: { addressError: null } });
    dispatchRedux(setCustomerInfo({ addressError: null }))
  }, [address]);

  async function handleAddressApply(place) {
    // dispatch({ type: 'setCartData', payload: { ...cartData, hypur_blocked: 1 } });
    dispatchRedux(setCartData({ ...cartData, hypur_blocked: 1 }))
    const newDeliveryDetails = {
      ...place,
      streetName: place.streetName || '',
      streetNumber: place.streetNumber || '',
      deliveryTypeSelected:
        // eslint-disable-next-line no-nested-ternary
        ((deliveryTypeSelected == SCHEDULE_TYPE_ASAP || deliveryTypeSelected == SCHEDULE_TYPE_ASAP_PRIORITY) && asapEnabled) ||
          geoFenceRef.current
          ? deliveryTypeSelected == SCHEDULE_TYPE_ASAP_PRIORITY
            ? SCHEDULE_TYPE_ASAP_PRIORITY
            : SCHEDULE_TYPE_ASAP
          : SCHEDULE_TYPE_FUTURE,
      deliveryDate: 0,
      deliveryTime:
        (deliveryTypeSelected == SCHEDULE_TYPE_ASAP && asapEnabled) || geoFenceRef.current
          ? getCurrentTime()
          : getFirstAvailableTimeSlot(getDefaultDeliveryDate(), appConfig.isGrassdoor, deliveryDays),
      deliveryDateTime: DEFAULT_DATETIME
    };

    const userInfo = StorageService.getUserData() || {};
    if (!checkOutUpdateDetails.deliveryDetails.address) {
      trackEvent('Checkout Step Viewed', {
        checkout_id: userInfo.phone,
        step: 1,
        shipping_method: deliveryTypeSelected ? deliveryTypeSelected + 2 : 0,
        payment_method: 0
      });
      trackEvent('Checkout Step Completed', {
        checkout_id: userInfo.phone,
        step: 1,
        shipping_method: deliveryTypeSelected ? deliveryTypeSelected + 2 : 0,
        payment_method: 0
      });
    }

    // dispatch({
    //   type: 'updateCheckOutDetails',
    //   payload: { ...checkOutUpdateDetails, deliveryType: true }
    // });

    dispatchRedux(updateCheckOutDetails({ ...checkOutUpdateDetails, deliveryType: true }))

    // dispatch({ type: 'saveLocation', payload: newDeliveryDetails });
    const { validZipcode } = await checkZipCodeStatus(newDeliveryDetails);
    dispatchRedux(saveLocation(newDeliveryDetails))
    if (!validZipcode) {
      dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup }))
    } else {
      setEditAddress(false);
    }
  }

  async function onPlacesChanged(place) {
    handleAddressApply({ ...place, address_id: 0 });
  }

  async function onHistoryPlacesChanged(placeObj) {
    if (placeObj) {
      const newDeliveryDetails = {
        ...placeObj,
        fromMap: false,
        streetNumber: placeObj.streetNumber,
        streetName: placeObj.streetName
      };
      if (placeObj.placeId) {
        // dispatch({ type: 'saveLocation', payload: { ...placeObj, fromMap: false } });
      } else if (placeObj.place_id) {
        newDeliveryDetails.placeId = placeObj.place_id;
        delete newDeliveryDetails.place_id;
        // dispatch({ type: 'saveLocation', payload: { ...placeObj, placeId: placeObj.place_id, fromMap: false } });
      } else {
        const data = await getPlaceId({ lat: placeObj.latitude, lng: placeObj.longitude });
        newDeliveryDetails.placeId = data.place_id;
        // dispatch({ type: 'saveLocation', payload: { ...placeObj, placeId: data.placeId, fromMap: false } });
      }
      handleAddressApply(newDeliveryDetails);
    }
  }

  async function handleDeleteClick(val) {
    setShowConfirmation(false);
    if (val) {
      await deleteHistoryAddress(addressToDeleted);
      addressHistoryMutate();
    }
  }

  const onAddressChange = list => {
    setAddressList(list);
  };

  const renderAddress = () => {
    if (address) {
      if (postcode && streetName) {
        return `${streetNumber || ''} ${streetName}, ${postcode}`;
      }
      return address;
    }
    return 'Set Delivery Address';
  };

  const [isCartLocked, setCartLocked] = useState(false);

  useEffect(() => {
    const { lockCart = false, address: initialAddress = null } = storageService.getInitialWebviewData();
    setCartLocked(initialAddress && lockCart);
  }, []);

  return (
    <>
      <div className={`addressbox-parent form-group mb-0 address-sticky  ${className}`}>
        <div
          id="checkout-address-block"
          className="set-delivery-block"
          onClick={e => (isMobile() ? (editAddress ? setEditAddress(false) : setEditAddress(true)) : null)}
        >
          <div className="d-flex justify-content-between align-items-center">
            {/* V3 NOTE: check below */}
            <h6
              className={`${isMedicalCard
                ? 'd-none'
                : `${asapEnabled ? 'mt-lg-2' : ''} pb-lg-0 pb-2 mb-0${process.env.NEXT_PUBLIC_CHECKOUT_ONLY !== 'true' ? ' d-flex align-items-center flex-wrap bold' : ''
                }`
                }`}
            >
              {isMedicalCard ? (
                ''
              ) : addressError ? (
                <>
                  Delivery address
                  <span className="error-message ml-2 d-flex align-items-center">
                    <button className="mr-1">
                      <i className="icon-info-italic" />
                    </button>
                    {addressError}
                  </span>
                </>
              ) : (
                'Delivery address'
              )}
            </h6>
            {fromCheckout && !addressError && !isDefaultAddress && isCartLocked ? null : (
              <button
                className={`btn btn-sm  col-auto one-em-font link-color pt-0  text-decoration-none fs-14 ${isMedicalCard ? 'abs-btn-medical' : 'abs-btn '
                  }`}
                title="Change"
                type="button"
                onClick={() => (!isMobile() ? (editAddress ? setEditAddress(false) : setEditAddress(true)) : null)}
              >
                {editAddress ? (
                  <>
                    <i className="icon-down fs-14 d-none d-lg-inline-block " />
                  </>
                ) : (
                  'Change'
                )}
              </button>
            )}
          </div>
          <>
            <div className="addres-logged-in truncate reduce-address-width">
              <i className="icon-pin mr-1" />
              {renderAddress()}
            </div>

            {error ? <div className="error-msg">{error}</div> : null}
          </>
        </div>

        {editAddress && (
          <>
            <div className="history-addr-block half-rem-mb">
              <div className={`${addressHistory?.length ? 'history-addr-bg ' : ''}`}>
                {addressHistory &&
                  addressHistory?.map((item, i) => (
                    <div className="half-rem-mt d-flex justify-content-between history-addr-mb " key={i}>
                      <AddressHistoryItem
                        key={`address_${item.address_id}`}
                        id={`historyLocation_${i + 1}`}
                        data={item}
                        onAddressChange={() => {
                          onHistoryPlacesChanged(item);
                        }}
                        isSelected={addressId === item.address_id}
                        className="history-addr"
                        forAddressHistoryCheckout
                      />
                      <span
                        className={`${isMobile() ? 'icon-trash-alt2' : 'icon-close'} cursor-pointer`}
                        onClick={() => {
                          setShowConfirmation(true);
                          setAddressToDelete(item.address_id);
                        }}
                      />
                    </div>
                  ))}
              </div>

              <div>
                <div className="d-flex position-relative">
                  <SearchBar
                    onPlacesChanged={onPlacesChanged}
                    isNotfocus={isNotfocus}
                    placeholder="Enter new address"
                    className="w-100 p-0"
                    addressList={addressList}
                    onAddressChange={onAddressChange}
                  />
                </div>
                {error ? <div className="error-msg">{error}</div> : null}
              </div>
            </div>
          </>
        )}
      </div>
      {showConfirmation ? (
        <Confirm
          text="Are you sure you want to delete this address ?"
          heading="Delete Address"
          handleConfirmClick={handleDeleteClick}
        />
      ) : null}
    </>
  );
};

export default EditAddress;
